@import "mixins";
@import "variables";
@import "extends";
@import url("https://fonts.googleapis.com/css2?family=Roboto+Condensed&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@700&display=swap");

a {
  position: relative;
  z-index: 99;
}
h2,
h3,
h4,
h5,
h6 {
  background: transparent !important;
}
::-webkit-scrollbar {
  width: 5px;
}
/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 20px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #4daf42;
  border-radius: 10px;
}
body {
  background-color: #f6f6f6 !important;
  overflow-x: hidden !important;
  overflow-y: auto !important;
  font-family: "Roboto Consended", sans-serif !important;
}
.navbar-light .navbar-toggler {
  outline: none;
}
:root {
  --header-height: 300px;
}
.border-radius-20 {
  border-radius: 20px;
}
.position-rel {
  position: relative;
  z-index: 99;
}
.main-100 {
  width: 100%;
  display: block;
  overflow: hidden;
  height: 100%;
  padding: 0 50px !important;
  &:before {
    content: " ";
    position: absolute;
    width: 600px;
    height: 600px;
    background-image: url("./../img/leaf1.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    top: -27rem;
    left: 10rem;
    z-index: -1;
  }
  &:after {
    content: " ";
    position: absolute;
    width: 700px;
    height: 700px;
    background-image: url("./../img/leaf2.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    top: -5rem;
    left: -36rem;
    z-index: -1;
  }
}
.main {
  width: 100%;
  display: block;
  overflow: hidden;
  min-height: 100vh;
  padding: 0 50px !important;
  &:before {
    content: " ";
    position: absolute;
    width: 600px;
    height: 600px;
    background-image: url("./../img/leaf1.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    top: -27rem;
    left: 10rem;
    z-index: -1;
  }
  &:after {
    content: " ";
    position: absolute;
    width: 700px;
    height: 700px;
    background-image: url("./../img/leaf2.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    top: -5rem;
    left: -36rem;
    z-index: -1;
  }
}

.nav {
  justify-content: center;
}

.h-100vh {
  height: 100vh;
}

.main-support {
  width: 100%;
  display: block;
  padding: 0 50px !important;
}
.MuiBadge-anchorOriginTopRightRectangle {
  transform: scale(1) translate(45%, -45%) !important;
  background-color: #4daf42;
  color: white;
}

.header {
  position: fixed !important;
  z-index: 9999;
  top: 0;
  width: 100%;
  left: 0;
  right: 0;
  padding: 10px 60px !important;
  justify-content: space-between !important;
  transition: 0.4s ease-in-out;
  &.white {
    nav {
      .nav-menu {
        a {
          color: white;
        }
      }
    }
  }
  &.active {
    box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.12);
    background: white;
    padding-top: 20px;
    padding-bottom: 20px;
    nav {
      .logo {
        transform: translateY(2px);
      }
    }
  }

  @media (max-width: 1200px) {
    position: fixed !important;
    top: 0;
    background: transparent;
    .navbar-collapse {
      position: fixed !important;
      width: var(--header-height) !important;
      height: 100vh !important;
      left: calc(-1 * var(--header-height)) !important;
      top: 0 !important;
      transition: 0.3s ease-in-out !important;
      z-index: 9999;
      background: #fff;
      .navbar-nav {
        li {
          display: block;
          padding: 10px;
          color: #000;
          a {
            font-size: 20px;
            padding: 0px;
            transition: 0.4s ease-in-out;
            &:hover {
              padding-left: 40px;
            }
          }
        }
      }
      &.show {
        left: 0 !important;
        transition: 0.3s ease-in-out;
      }
    }
  }



}
.header-link {
  ul {
    list-style: none;
  }
  li {
    margin-right: 30px;
  }
  a {
    color: #357d2d;
    font-size: 14px;
    text-decoration: none;
    &:hover {
      text-decoration: none;
      color: #357d2d;
    }
  }
}
.line {
  position: relative;
  z-index: 21;
  &::before {
    content: "";
    position: absolute;
    width: 0%;
    z-index: -1;
    bottom: -5px;
    height: 2px;
    opacity: 0;
    transition: all 0.5s ease-in;
  }
  &:hover::before {
    left: 0;
    opacity: 1;
    // bottom: -5px;
    width: 100%;
    // margin-top: -5px;
    background: #4daf42;
    // height: 2px;
  }
}
// .line {
//   &:hover {
//     &::before {
//       left: 0;
//       bottom: -5px;
//       width: 100%;
//       margin-top: -5px;
//       background: #4daf42;
//       height: 2px;
//     }
//   }
// }
.header-img {
  img {
    width: 25px;
    height: 25px;
    object-fit: contain;
  }
  div {
    position: absolute;
    top: -10px;
    left: 12px;
    background-color: #4daf42;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    border: 3px solid white;
    p {
      font-weight: 500;
      color: white;
      margin-left: 7px;
    }
  }
}
.select {
  margin: 0 !important;
  select {
    border-bottom: 1px solid #ccc !important;
    color: white;
    padding-left: 10px;
    font-size: 15px;

    option {
      font-size: 15px;
      appearance: none;
      -webkit-appearance: none;
      color: white !important;
      border-bottom: 1px solid white !important;
      background-color: #4daf42 !important;
    }
  }
}

.error-text {
  font-size: 12px;
  transition: 0.3s ease-in-out;
  margin-bottom: 10px;
}
.error {
  border-color: red !important;
  color: red !important;
  &::-webkit-input-placeholder {
    /* Edge */
    color: red;
  }
  &:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: red;
  }
  &::placeholder {
    color: red !important;
  }
}
.donate {
  p {
    background-color: #4daf42;
    font-size: 16px;
    font-weight: 400;
    color: white;
    padding: 10px 40px;
    border-radius: 40px;
    margin: 0 10px;
    transform: scale(1);
    box-shadow: none;
    transition: all 0.3s ease-in;
    &:hover {
      transform: scale(1.1);
      box-shadow: 5px 5px 30px grey;
    }
  }
  a:hover {
    text-decoration: none;
  }
}
.lang {
  @media (max-width: 1199px) {
    .Dropdown {
      .dropdown-menu.show {
        display: block;
      }
      display: block !important;
      .dropdown-menu {
        display: block !important;
        position: relative;
      }
    }
    .dropdown-basic {
      display: flex;
      justify-content: space-between;
    }
  }
  button {
    color: white;
    border: none;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    position: relative;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
  .dropdown {
    .dropdown-item {
      background: transparent !important;
      padding: 5px 0;
      margin-left: 2px;
      img {
        width: 25px;
        height: 25px;
      }
    }
    .dropdown-menu {
      background: transparent !important;
      border: none !important;
    }
    .dropdown-toggle::after {
      display: none !important;
    }
  }
  .dropdown-toggle:focus {
    box-shadow: 5px 5px 10px gray;
    transition: all 0.5s;
  }
}
.slider-arrow {
  position: absolute;
  z-index: 70;
  bottom: 2rem;
  left: 40rem;
  display: flex;
  img {
    margin: 0 10px 15px 10px;
  }
  p {
    font-size: 14px;
  }
}

.main-card {
  margin:0  20px;
  a {
    background-color: #4daf42;
    margin: 0 20px 20px  0;
    height: 180px;
    width: 180px;
    opacity: 0.9;
    border-radius: 20px;
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: scale(1);
    transition: all 0.6s;
    &:hover {
      opacity: 1;
      transform: scale(1.1);
      text-decoration: none !important;
    }
  }
  a img {
    width: 60px;
    height: 60px;
  }
  a p {
    margin-top: 15px;
    color: white;
    font-size: 20px;
  }
}

.year-slider {
  .years-table {
    position: relative;
    z-index: 3;
    margin: 0 23rem 5rem 4rem;
    height: 145px;
    border-bottom: 5px solid #4daf42;
    .number {
      height: 160px;
      position: relative;
      margin-top: 80px;
      bottom: 0;
      transition: 0.1s ease-in-out;
      p {
        color: black;
        font-size: 18px;
        font-weight: bold;
        // transform: rotate(-90deg);
        cursor: pointer;
        &::after {
          position: absolute;
          content: " ";
          left: 10px;
          z-index: 5;
          border-radius: 50%;
          bottom: 88px;
          width: 18px;
          height: 18px;
          border: 4px solid #4daf42;
          background: #fff;
          cursor: pointer;
        }
      }
      &.active,
      &:focus {
        transition: 0.4s ease-in-out;
        position: relative;
        p {
          transition: 0.4s ease-in-out;
          color: #4daf42;
          font-size: 30px;
          font-weight: bold;
          position: relative;
          z-index: 21;
          // &::before {
          //   position: absolute;
          //   content: " ";
          //   right: -90px;
          //   bottom: 60px;
          //   width: 70px;
          //   margin-top: -5px;
          //   background: white;
          //   height: 0.5px;
          //   transition: 0.1s ease-in-out;
          // }
          &::after {
            left: 20px;
            bottom: -30px;
            transition: 0.4s ease-in-out;
            width: 25px;
            height: 25px;
            transform: scale(1.1);
            background-color: #4daf42;
          }
        }
      }
    }
  }

  .slick-slider {
    position: relative;
    z-index: 3;
    margin: 0 4rem;
    width: 100%;

    &.aboutSlider {
      overflow: hidden;
      padding-bottom: 20px;
      .slick-list {
        overflow: unset !important;
      }
    }
    .slick-list {
      height: 145px;
      border-bottom: 5px solid #4daf42;
      font-family: "Karla-Regular", sans-serif;
      font-weight: 700;
    }
    // .slick-track {
    //   // margin-top: 50px;
    // }
    .slider-numb {
      height: 160px;
      position: relative;
      margin-top: 80px;
      bottom: 0;
      transition: 0.1s ease-in-out;
      cursor: pointer;
      p {
        color: black;
        font-size: 18px;
        font-weight: bold;
        // transform: rotate(-90deg);
        &::after {
          position: absolute;
          content: " ";
          left: 10px;
          z-index: 5;
          border-radius: 50%;
          bottom: 88px;
          width: 18px;
          height: 18px;
          border: 4px solid #4daf42;
          background: #fff;
        }
      }
      &.active,
      &:focus {
        transition: 0.4s ease-in-out;
        position: relative;
        p {
          transition: 0.4s ease-in-out;
          color: #4daf42;
          font-size: 30px;
          font-weight: bold;
          position: relative;
          z-index: 21;
          &::before {
            position: absolute;
            content: " ";
            right: -190px;
            bottom: 60px;
            width: 100px;
            margin-top: -5px;
            background: white;
            height: 0.5px;
            transition: 0.1s ease-in-out;
          }
          &::after {
            left: 20px;
            bottom: -30px;
            transition: 0.4s ease-in-out;
            width: 25px;
            height: 25px;
            transform: scale(1.1);
            background-color: #4daf42;
          }
        }
      }
    }
  }

  .prev {
    background-color: transparent !important;
    border: none;
    position: relative;
    left: 0;
  }

  .next {
    background-color: transparent !important;
    border: none;
    position: relative;
    right: 0;
    img {
      transform: rotate(180deg);
    }
  }
}
.partnersImg {
  position: relative;
  width: 180px !important;
  height: 100px;
  img {
    position: absolute;
    height: 100%;
    width: 100%;
    object-fit: contain;
  }
  &:focus,
  &:hover {
    outline: none;
    cursor: pointer;
  }
}
.full-partners {
  span {
    color: #4daf42;
    font-size: 16px;
  }
  p {
    font-size: 45px;
    font-weight: 500;
    margin: 0;
  }
  img {
    height: 100px;
    width: 100%;
    object-fit: contain;
  }
}
.public-modal {
  position: relative;
  &:before {
    content: " ";
    position: absolute;
    width: 100%;
    height: 100%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: left;
    top: 0;
    left: 0;
    z-index: 0;
 
  }

  .modal-img {
    width: 100%;
    height: 250px;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      z-index: 5;
    }
  }
  .modal-text {
    p {
      font-size: 14px;
      color: black;
    }
  }
}
.section-link {
  margin-bottom: 0;
  margin-top: 1rem;
  a {
    font-weight: bold;
    font-size: 18px;
    color: #4daf42;
    &:hover {
      text-decoration: none;
      color: #4daf42;
    }
  }
}
.section-text {
  h2 {
    font-weight: bold;
    font-size: 46px;
    color: black;
    width: 100%;
  }
  span,
  p {
    font-size: 14px;
    color: #6d6d6d;
  }
  
}
.rule p {
  font-size: 14px;
  color: gray;
}
.form-control-range {
  -webkit-appearance: none;
  width: 100%;
  height: 7px;
  background: #d3d3d3;
  outline: none;
  opacity: 0.8;
  border-radius: 20px;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
  &:hover {
    opacity: 1;
  }
}
.form-control-range::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  background: #4daf42;
  cursor: pointer;
  border-radius: 50%;
  opacity: 1;
  &:hover {
    opacity: 1;
  }
}
.range-percent {
  text-transform: uppercase;

  p {
    font-size: 16px;
    margin: 0 0 15px 0;
    color: #4daf42;
  }
  span {
    font-weight: bold;
    font-size: 12px;
  }
}
.range-about {
  p {
    font-size: 12px;
  }
}
.links {
  font-size: 12px;
  margin-top: 1rem;
  button {
    background-color: #4daf42;
    padding: 10px 20px 10px 20px;
    border-radius: 20px;
    color: white !important;
    margin-right: 30px;
    border: none;
    font-size: 12px;
    &:hover {
      text-decoration: none;
      color: white;
    }
  }
  a {
    color: white;
    &:hover {
      text-decoration: none;
      color: white;
    }
  }
}
.section-videos {
  position: absolute;
  z-index: 10;
  bottom: 0;
  left: 0;
  div {
    &:hover {
      .lay {
        opacity: 0;
        transition: all 0.3s;
      }
      .play {
        opacity: 1;
        transition: all 0.3s;
      }
    }
  }
  span {
    color: white;
    position: absolute;
    z-index: 20;
    top: 60%;
    left: 20%;
    font-size: 14px;
  }
  img {
    width: 230px;
    object-fit: cover;
  }
  .img2 {
    border-top-right-radius: 20px;
    img {
      border-top-right-radius: 20px;
    }
    .play {
      border-radius: unset;
    }
    .lay {
      border-top-right-radius: 20px;
    }
  }
  .scroll-down {
    background-color: #4daf42;
    a {
      color: white;
      &:hover {
        text-decoration: none;
        color: white;
      }
    }
    p {
      font-size: 14px;
      padding: 10px;
      margin-top: 55px;
      transform: rotate(-90deg);
    }
  }
  .play {
    width: 30px;
    height: 30px;
    position: absolute;
    z-index: 20;
    opacity: 0.5;
    top: 5%;
    left: 5%;
    cursor: pointer;
  }
}
.lay {
  position: absolute;
  z-index: 20;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: linear-gradient(grey, black, black, black);
  opacity: 0.5;
  border-radius: 20px;
}
.nav-tabs {
  position: absolute;
  top: 100px;
  right: 20px;
  border-bottom: none !important;
  background-color: transparent;
  color: black;
  font-size: 13px;
  .nav-link {
    padding: 0px !important;
    margin: 0.5rem 1rem;
    border: none !important;
    position: relative;

    &::before {
      content: "";
      opacity: 0%;
      width: 0px;
      height: 2px;
      display: block;
      position: absolute;
      left: 0px;
      bottom: -5px;
      background-color: #4daf42;
      border-radius: 4px;
      margin-bottom: 0px;
      transition: all 0.7s ease-in-out;
    }

    &:is(:hover, :active, :focus)::before {
      width: 100%;
      border-top: 10px;
      opacity: 100%;
    }

    &:focus::before {
      height: 2px !important;
      width: 50%;
    }
  }
  .nav-link.active {
    color: #4daf42 !important;
    background-color: transparent !important;
    // border-color: #dee2e6 #dee2e6 #fff;
  }
  a {
    color: #4daf42;
    font-weight: 600;
    font-size: 14px;
    &:hover {
      color: #4daf42;
    }
  }
}
.home {
  overflow: hidden;
  &:before {
    content: " ";
    position: absolute;
    width: 600px;
    height: 600px;
    background-image: url("./../img/leaf1.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    top: -27rem;
    left: 10rem;
    z-index: -1;
  }
  &:after {
    content: " ";
    position: absolute;
    width: 700px;
    height: 700px;
    background-image: url("./../img/leaf2.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    top: -5rem;
    left: -36rem;
    z-index: -1;
  }
  a {
    border-radius: 20px;
    box-shadow: none;
    transition: 0.3s ease-in-out;
    &:hover {
      text-decoration: none;
      box-shadow: 5px 5px 5px #ccc;
    }
    span {
      color: white;
      background-color: #4daf42;
      padding: 5px 13px;
      border-radius: 20px;
      font-size: 13px;
      cursor: pointer;
    }
  }
}
.social-icons {
  position: absolute;
  z-index: 1;
  left: 20px;
  bottom: 30px;
  ul {
    list-style: none;
    padding: 0;
  }
  li {
    margin-top: 10px;
  }
  img {
    width: 20px;
    height: 20px;
    object-fit: contain;
  }
}
.leafRight {
  position: absolute;
  z-index: 0;
  top: -4rem;
  right: 3rem;
  width: 100%;
  height: 100%;
  img {
    width: 54rem;
    height: 54rem;
  }
}
.leafLeft {
  position: absolute;
  z-index: 15;
  top: -4rem;
  left: -9rem;
  width: 100%;
  height: 100%;
  img {
    width: 54rem;
    height: 54rem;
  }
}
.section2 {
  margin-top: 7rem;
}
.our-mission {
  overflow: hidden !important;
  padding: 0 50px;
  &:before {
    content: " ";
    position: absolute;
    width: 500px;
    height: 500px;
    background-image: url("./../img/leaf1.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    transform: rotate(70deg);
    top: -5rem;
    right: -23rem;
    z-index: -1;
  }
  &:after {
    content: " ";
    position: absolute;
    width: 700px;
    height: 700px;
    background-image: url("./../img/leaf2.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    top: 5rem;
    right: -37rem;
    z-index: -1;
  }
}
.section-two-links {
  font-size: 12px;
  margin-top: 1rem;
  a:nth-child(1) {
    background-color: #4daf42;
    padding: 10px 20px 10px 20px;
    border-radius: 20px;
    color: white;
    margin-left: 20px;
    font-weight: normal;
    &:hover {
      text-decoration: none;
      color: white;
    }
  }
  a {
    color: #929292;
    font-weight: bold;
    margin-left: 20px;
    &:hover {
      text-decoration: none;
      color: #929292;
    }
  }
}

.section-three {
  overflow: hidden;
  background-color: #4daf42;
  padding: 0 50px;
}

.section-three-text {
  p {
    font-size: 46px;
    color: white;
    width: 120%;
    margin: 0;
  }
  span {
    font-size: 14px;
    color: white;
  }
}
.media-border{
  border-radius: 20px;
  padding: 15px;


}
.line-link {
  position: relative;
  z-index: 21;
  &.media{
    color: #4daf42 !important;
    border-bottom: 1px solid transparent;
    transition: 0.2s ease-in-out;
    &:hover{
      border-color: #4daf42;
      transition: 0.2s ease-in-out;
    }
  }
  &::before {
    content: "";
    position: absolute;
    width: 0;
    z-index: -1;
    transition: all 0.5s ease-in;
  }
  &:hover {
    &::before {
      left: 0;
      bottom: -5px;
      width: 100%;
      margin-top: -5px;
      background: white;
      height: 2px;
    }
  }
}
.section-three-numbers {
  font-weight: bold;
  color: white;
  p {
    font-size: 14px;
    margin: 0;
  }
  span {
    font-size: 40px;
  }
  a {
    position: relative;
    z-index: 10;
    background-color: white;
    border: none;
    outline: none;
    border-radius: 20px;
    color: #4daf42;
    padding: 10px 20px;
    font-weight: 400;
    &:hover {
      text-decoration: none;
      color: #4daf42;
    }
  }
  .directions {
    position: relative;
    z-index: 99;
  }
}

.section-three-leaf {
  svg {
    position: absolute;
    z-index: 0;
    top: -12rem;
    right: -4rem;
    width: 400px;
    transform: rotate(50deg);
  }
}
.section-three-leafwhite {
  overflow: hidden;
  svg {
    position: absolute;
    z-index: 0;
    bottom: -18rem;
    width: 400px;
    left: -13rem;
    overflow: hidden;
    transform: rotate(215deg);
  }
}
.section-four {
  overflow: hidden;
  padding: 0 50px;
  &:before {
    content: " ";
    position: absolute;
    width: 500px;
    height: 500px;
    background-image: url("./../img/leaf1.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    transform: rotate(50deg);
    top: -10rem;
    right: -21rem;
    z-index: -1;
  }
  &:after {
    content: " ";
    position: absolute;
    width: 700px;
    height: 700px;
    background-image: url("./../img/leaf2.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    top: 8rem;
    left: -37rem;
    z-index: -1;
  }
}
.section-four-links {
  ul {
    list-style: none;
  }
  a {
    color: #929292;
    font-size: 12px;
    margin-right: 10px;
    &:hover {
      text-decoration: none;
      color: #4daf42;
    }
  }
}
.section-four-link {
  position: relative;
  z-index: 10;
  margin-top: -0.5rem;
  a:hover {
    text-decoration: none;
  }
  p {
    font-size: 12px;
    background-color: #4daf42;
    color: white;
    padding: 10px 25px;
    border-radius: 30px;
    margin: 0 30px 0 20px;
  }
}
.projects {
  margin-top: 2rem;
  overflow: hidden;
  width: 100%;
  .slick-slider {
    .slick-list {
      padding: 0 !important;
      overflow: unset;
    }
    .slick-slide {
      transition: 0.3s all;
      padding: 0 10px;
    }

    .slick-center {
      transition: 0.3s all;
    }
  }
  .lay {
    border-radius: 20px;
  }
  img {
    position: absolute;
    z-index: 10;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 20px;
  }
  .project-text {
    position: absolute;
    z-index: 20;
    color: white;
    padding: 0 20px;
    top: 25%;
    left: 0;
    a {
      font-weight: bold;
      font-size: 12px;
      color: white;
      text-transform: uppercase;
      &:hover {
        text-decoration: none;
      }
    }
    h3 {
      margin-top: 10px;
      font-weight: bold;
      font-size: 20px;
    }
    p {
      font-weight: 300;
      font-size: 12px;
    }
    span {
      font-size: 10px;
    }
  }
  .project {
    //width: 550px;
    height: 230px;
    background-color: #4daf42;
    border-radius: 20px;
    &:hover {
      .lay {
        opacity: 0;
        transition: all 0.3s;
      }
    }
  }
}
.section-four-leaf {
  img {
    position: absolute;
    z-index: -1;
    top: 10rem;
    right: -12rem;
    width: 850px;
  }
}
.section-four-leaf-border {
  img {
    position: absolute;
    z-index: -1;
    top: 7.5rem;
    right: -15.5rem;
    width: 950px;
  }
}
.events {
  margin-top: 10px;
  overflow: hidden;
  position: relative;
  z-index: 99;
  .slick-slider {
    .slick-list {
      padding: 0 !important;
      overflow: unset;
    }
    .slick-slide {
      transition: 0.3s all;
      padding: 0 10px;
    }

    .slick-center {
      transition: 0.3s all;
    }
  }
  .event {
    position: relative;
    display: block;
    background-color: white;
    height: 245px;
    border-radius: 20px;
    img {
      position: absolute;
      z-index: 10;
      top: 0;
      left: 0;
      width: 40%;
      height: 100%;
      object-fit: cover;
      border-top-left-radius: 20px;
      border-bottom-left-radius: 20px;
    }
    .event-text {
      position: absolute;
      z-index: 11;
      top: 0;
      left: 40%;
      width: 60%;
      padding: 10px;
      text-overflow: ellipsis;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
      p {
        font-weight: bold;
        font-size: 20px;
        margin: 0;
        line-break: anywhere;
      }
      span {
        font-size: 10px;
      }
    }
    .event-range {
      position: absolute;
      z-index: 11;
      left: 40%;
      bottom: 25px;
      padding: 10px 200px 0 10px;
      width: 100%;
      .form-control-range {
        -webkit-appearance: none;
        width: 100%;
        height: 3px;
        background: #d3d3d3;
        outline: none;
        opacity: 0.8;
        border-radius: 20px;
        -webkit-transition: 0.2s;
        transition: opacity 0.2s;
        &:hover {
          opacity: 1;
        }
      }
      .form-control-range::-webkit-slider-thumb {
        -webkit-appearance: none;
        appearance: none;
        width: 10px;
        height: 10px;
        background: #4daf42;
        cursor: pointer;
        border-radius: 50%;
        opacity: 1;
        &:hover {
          opacity: 1;
        }
      }
      .range-percent {
        text-transform: uppercase;
        p {
          font-size: 12px;
          margin: 0 0 10px 0;
          color: #4daf42;
        }
        span {
          font-weight: bold;
          font-size: 10px;
        }
      }
      .range-about {
        p {
          font-size: 10px;
        }
      }
    }
    .event-link {
      position: absolute;
      z-index: 10;
      bottom: 7px;
      left: 58%;
      p {
        background-color: #4daf42;
        color: white;
        padding: 2px 30px;
        border-radius: 20px;
        font-size: 12px;
        margin: 3px 0 0 0;
      }
      span {
        background-color: white;
        color: #4daf42;
        padding: 2px 30px;
        border-radius: 20px;
        font-size: 12px;
        border: 1px solid #4daf42;
      }
      a {
        margin-right: 20px;
      }
      a:hover {
        text-decoration: none;
      }
    }
  }
}

.leaf-celebrity {
  margin-top: 2rem;
  .leaf2 {
    position: absolute;
    z-index: 0;
    left: -12rem;
    top: -5rem;
    width: 60rem;
    height: 60rem;
  }
  .leaf-2 {
    position: absolute;
    z-index: 50;
    top: -7rem;
    left: 0;
    width: 100% !important;
    height: 900px;
    .name {
      position: absolute;
      z-index: 60;
      top: 20rem;
      left: 9rem;
      color: white;
      //transform: rotate(-90deg);
      p {
        font-size: 46px;
        font-weight: bold;
        margin: 0;
      }
      span {
        font-size: 14px;
        font-weight: 300;
      }
    }
    .img {
      height: 100%;
      width: 100%;
      object-fit: cover;
      //transform: rotate(-90deg);
    }
    mask-image: url("./../img/leaf2.svg");
    mask-size: 800px;
    mask-repeat: no-repeat;
    mask-position: center;
    //transform: rotate(90deg);
  }
  //&:before {
  //  content: " ";
  //  position: absolute;
  //  width: 1050px;
  //  height: 1050px;
  //  background-image: url("./../img/border.svg");
  //  background-size: contain;
  //  background-repeat: no-repeat;
  //  background-position: center;
  //  top: -7rem;
  //  left: -15rem;
  //  z-index: 0;
  //}
}
.section-fifth-slider-arrow {
  position: absolute;
  z-index: 70;
  bottom: 11rem;
  left: 3rem;
  display: flex;
  img {
    margin: 0 10px 15px 10px;
  }
  p {
    font-size: 14px;
  }
}

.footerContainer {
  height: 250px;
}

.footer {
  overflow: hidden;
  background-color: #4daf42;
  width: 100%;
  height: 100%;
  padding: 30px 50px !important;
  display: block;
  &:before {
    content: " ";
    position: absolute;
    width: 800px;
    height: 800px;
    background-image: url("./../img/leaf2.svg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    filter: brightness(4);
    transform: rotate(100deg);
    top: 0rem;
    right: -19rem;
    overflow: hidden;
    z-index: 0;
  }
  &:after {
    content: " ";
    position: absolute;
    width: 500px;
    height: 500px;
    background-image: url("./../img/leaf1.svg");
    filter: brightness(1.5);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    opacity: 1;
    top: -4rem;
    left: -15rem;
    z-index: 0;
  }
  .rules a {
    color: white;
    cursor: pointer;
    font-size: 18px;
    &:hover {
      text-decoration: none;
    }
  }
  .footer-text {
    color: white;
    p {
      font-size: 12px;
      margin-top: 15px;
      margin-bottom: 5px;
    }
    span {
      font-size: 12px;
    }
    a {
      color: white;
    }
    a:hover {
      text-decoration: none;
      color: white;
    }
  }
}
.footer-social-icon {
  margin-top: 2rem;
  ul {
    padding: 0;
  }
  li {
    list-style: none;
    margin-right: 15px;
  }
  p {
    font-size: 12px;
    color: white;
  }
}
.footer-links {
  color: white;
  p {
    font-size: 16px;
  }
  ul {
    padding: 0;
  }
  li {
    list-style: none;
    margin-right: 25px;
    margin-bottom: 5px;
  }
  a {
    color: white;
    font-size: 12px;
    &:hover {
      text-decoration: none;
      color: white;
    }
  }
}
.footer-leaf {
  position: relative;
  z-index: 21;
  right: 0;
  cursor: pointer;
  &:before {
    content: " ";
    position: absolute;
    width: 180px;
    height: 180px;
    background-image: url("./../img/leaf2.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    bottom: -3rem;
    right: -2rem;
    transform: rotate(90deg);
    z-index: -1;
  }
  span {
    position: absolute;
    bottom: 1.7rem;
    right: 1rem;
    z-index: 21;
    color: white;
    font-size: 12px;
    &:hover {
      text-decoration: none;
      color: white;
    }
  }
}
//.styles_scroll-to-top__2A70v {
//  background-color: transparent!important;
//  right: -10px !important;
//  bottom: -35px !important;
//  position: absolute !important;
//  z-index: 2;
//  cursor: pointer;
//  border-radius: 7px;
//  width: 90px !important;
//  height: 90px !important;
//  transition: opacity 1s ease-in-out;
//  box-shadow: none !important;
//  border: none !important;
//  outline: none !important;
//  svg {
//    display: none;
//  }
//}
.toast {
  background-color: transparent !important;
  width: 100% !important;
  box-shadow: none !important;
  max-width: none !important;
  border: none !important;
}

.donate-number {
  color: black;
  margin-bottom: 20px;
  border-top: 1px solid #ccc;
  padding-top: 10px;
  //div {
  //  margin-right: 7rem;
  //}
  p {
    font-weight: bold;
    font-size: 30px;
    margin: 0;
  }
  span {
    font-size: 14px;
  }
}
.donor {
  margin-top: 1rem;
  div {
    position: relative;
    border-radius: 50%;
    border: 3px solid #4daf42;
    width: 50px;
    height: 50px;
    overflow: hidden;
    &:hover {
      transform: scale(1.3);
      transition: all 0.5s;
    }
  }
  img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 20;
  }
  div:nth-child(2) {
    position: absolute;
    left: 50px;
    z-index: 21;
  }
  div:nth-child(3) {
    position: absolute;
    left: 85px;
    z-index: 21;
  }
  div:nth-child(4) {
    position: absolute;
    left: 120px;
    z-index: 21;
  }
  div:nth-child(5) {
    position: absolute;
    left: 155px;
    z-index: 21;
  }
}
.donor-link {
  margin-top: 15px;
  a {
    font-size: 14px;
    color: white;
    background-color: #4daf42;
    border-radius: 20px;
    padding: 10px 20px;
    &:hover {
      text-decoration: none;
      color: white;
    }
  }
}
.donate-leaf {
  position: absolute;
  z-index: 5;
  top: -7rem;
  right: -20rem;
  &:before {
    content: " ";
    position: absolute;
    width: 1050px;
    height: 1050px;
    background-image: url("./../img/border.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    top: -2.5rem;
    right: -2.5rem;
    z-index: 50;
  }
  img {
    width: 60rem;
    height: 60rem;
  }
}
.donate-slider2 {
  margin-top: 7rem;
  position: relative;
  z-index: 10;
  overflow: hidden;
  width: 85%;
  margin-left: 8rem;
  .slick-slider {
    .slick-list {
      padding: 0 !important;
      overflow: unset;
    }
    .slick-slide {
      transition: 0.3s all;
      padding: 0 10px;
    }
    .slick-center {
      transition: 0.3s all;
    }
  }
  .donate-slider1-box {
    position: relative;
    display: block;
    background-color: white;
    height: 320px;
    border-radius: 30px;
    &:hover {
      .lay {
        opacity: 0;
        transition: all 0.3s;
      }
      .play {
        opacity: 1;
        transition: all 0.3s;
      }
    }
    video {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 20px;
    }
    img {
      position: absolute;
      z-index: 10;
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 20px;
      top: 0;
      left: 0;
    }
    .play {
      z-index: 22;
      width: 60px;
      height: 60px;
      top: 40%;
      left: 45%;
      opacity: 0.3;
      cursor: pointer;
    }
  }
  .donate-slider2-box {
    position: relative;
    display: block;
    background-color: white;
    height: 120px;
    border-radius: 30px;
    video {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 20px;
      position: absolute;
    }
    &:hover {
      .lay {
        opacity: 0;
        transition: all 0.3s;
      }
      .play2 {
        opacity: 1;
        transition: all 0.3s;
      }
    }
    img {
      position: absolute;
      z-index: 10;
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 20px;
      top: 0;
      left: 0;
    }
    .play2 {
      z-index: 22;
      width: 30px;
      height: 30px;
      top: 35%;
      left: 40%;
      opacity: 0.3;
      cursor: pointer;
    }
  }
}
.donate-slider-links {
  margin-top: 4rem;
  position: relative;
  z-index: 10;
  a:hover {
    text-decoration: none;
  }
  p {
    color: white;
    font-size: 12px;
    margin: 0;
  }
  a {
    margin-right: 10px;
  }
}
.donation {
  position: absolute;
  top: 5rem;
  left: 7rem;
  z-index: -1;
}
.story {
  position: absolute;
  bottom: 0px;
  left: -30px;
  transform: rotate(-90deg);
  background-color: #4daf42;
  padding: 30px 20px;
  z-index: 20;
  overflow: hidden;
  img {
    transform: rotate(90deg);
  }
  a {
    font-size: 14px;
    color: white;
    margin-left: 25px;
    &:hover {
      text-decoration: none;
      color: white;
    }
  }
}
.donate-section-one {
  overflow: hidden;
  &:before {
    content: " ";
    position: absolute;
    width: 500px;
    height: 500px;
    background-image: url("./../img/leaf1.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    top: -8rem;
    left: -21rem;
    transform: rotate(-100deg);
    z-index: -1;
  }
  &:after {
    content: " ";
    position: absolute;
    width: 700px;
    height: 700px;
    background-image: url("./../img/leaf2.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    top: 9rem;
    left: -37rem;
    z-index: -1;
  }
}
.donate-text {
  border-top: 1px solid #4daf42;
  margin-top: 20px;
  p {
    font-size: 16px;
    font-weight: bold;
    padding-top: 20px;
    color: #4daf42;
  }
}
.girl {
  // position: absolute;
  z-index: 99;
  // bottom: 0;
  left: 0;
  width: 42rem;
  height: 44rem !important;
}
.donate-link {
  margin-bottom: 1rem;
  margin-top: 2rem;
  a {
    font-size: 18px;
    color: black;
    &:hover {
      text-decoration: none;
      color: #4daf42;
      font-weight: bold;
    }
  }
}
.donate-contact {
  position: absolute;
  z-index: 15;
  left: 14rem;
  top: 10rem;
  color: white;
  text-align: left;
  p {
    font-size: 23px;
    font-weight: bold;
  }
  span {
    font-size: 12px;
  }
  p a {
    font-size: 23px;
    font-weight: bold;
    color: white;
    &:hover {
      text-decoration: none;
    }
  }
}
.donate-leaf2 {
  position: absolute;
  z-index: 5;
  top: -8rem;
  right: -51rem;
  img {
    width: 60rem;
    height: 60rem;
    transform: rotate(-100deg);
  }
}
.donate-section-four-text {
  p {
    font-weight: bold;
    font-size: 16px;
    color: #4daf42;
    text-transform: uppercase;
  }
  span {
    font-size: 14px;
  }
}
.payment {
  border-top: 1px solid #ccc;
  padding-top: 15px;
  .payment-amount {
    p {
      font-weight: bold;
      font-size: 18px;
      color: #4daf42;
    }
  }
  .payment-number {
    border-bottom: 1px solid #4daf42;
    width: 40%;
    p {
      font-weight: bold;
      font-size: 22px;
      margin: 0;
    }
    span {
      font-size: 12px;
      margin-left: 5px;
    }
  }
}
.payment-text {
  width: 70%;
  p {
    font-size: 12px;
    color: #4daf42;
  }
}
.payment-about {
  border-top: 1px solid #ccc;
  padding-top: 15px;
  .checkbox {
    width: 20px;
    height: 20px;
  }
  p {
    font-weight: 300;
    font-size: 12px;
    color: #202020;
    margin-top: 2px;
  }
  .payment-total {
    border-bottom: 1px solid #4daf42;
    width: 30%;
    p {
      font-size: 16px;
    }
    span {
      font-size: 10px;
      margin-left: 5px;
    }
  }
}
.payment-social-icons {
  position: absolute;
  z-index: 1;
  left: 1rem;
  bottom: 0;
  ul {
    list-style: none;
    padding: 0;
  }
  li {
    margin-top: 10px;
  }
}
//.main-section {
//  overflow: hidden;
//  &:before {
//    content: " ";
//    position: absolute;
//    width: 600px;
//    height: 600px;
//    background-image: url("./../img/leaf1.svg");
//    background-size: contain;
//    background-repeat: no-repeat;
//    background-position: center;
//    top: -27rem;
//    left: 10rem;
//    z-index: -1;
//  }
//  &:after {
//    content: " ";
//    position: absolute;
//    width: 700px;
//    height: 700px;
//    background-image: url("./../img/leaf2.svg");
//    background-size: contain;
//    background-repeat: no-repeat;
//    background-position: center;
//    top: -5rem;
//    left: -36rem;
//    z-index: -1;
//  }
//}
.payment-leaf1 {
  position: absolute;
  z-index: -1;
  top: -10rem;
  right: -18rem;
  transform: rotate(170deg);
  &:before {
    content: " ";
    position: absolute;
    width: 1050px;
    height: 1050px;
    background-image: url("./../img/border.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    top: -2.5rem;
    right: -3.5rem;
    z-index: -1;
  }
  img {
    width: 60rem;
    height: 60rem;
  }
}
.payment-card {
  position: absolute;
  z-index: 20;
  top: 8rem;
  right: 3rem;
}
.bank {
  margin-top: 4rem;
}
.payment-price {
  margin-top: 2rem;
  p {
    font-size: 14px;
    margin-right: 30px;
  }
  span {
    font-weight: bold;
    font-size: 24px;
  }
  span p {
    font-size: 12px;
    font-weight: normal;
    margin-left: 5px;
  }
}
.payment-info {
  input {
    outline: none;
    border: none;
    background-color: transparent;
    border-bottom: 1px solid #ccc;
    margin: 20px;
    width: 60%;
  }
  .cvv {
    width: 20%;
  }
}
.accept-payment {
  margin-top: 2rem;
  p {
    font-size: 14px;
  }
  a {
    background-color: #4daf42;
    font-size: 12px;
    color: #fff;
    padding: 10px 40px;
    border: none;
    border-radius: 30px;
    &:hover {
      text-decoration: none;
      color: white;
    }
  }
}
.back-track {
  margin-top: 4rem;
  p {
    color: #4daf42;
    font-size: 12px;
    margin-top: 5px;
  }
  a:hover {
    text-decoration: none;
  }
}

.payment-video {
  margin-top: 2rem;
  p {
    margin: 0 0 20px 0;
    font-size: 14px;
  }
  .video {
    width: 210px;
    height: 130px;
    border-radius: 10px;
    &:hover {
      .lay {
        opacity: 0;
        transition: all 0.3s;
      }
    }
    img {
      position: absolute;
      width: 100%;
      height: 100%;
      border-radius: 10px;
    }
    .play {
      width: 25px;
      height: 25px;
      top: 10%;
      left: 10%;
    }
    .lay {
      border-radius: 10px;
    }
    span {
      font-size: 14px;
      color: white;
      position: absolute;
      z-index: 20;
      left: 10%;
      bottom: 30%;
    }
  }
}
.section-terms {
  margin: 10px 0 0 10px;
  p {
    color: #6d6d6d;
    font-size: 14px;
    font-weight: normal;
    margin: 0 0 5px 0;
  }
  img {
    margin-bottom: 5px;
    margin-right: 10px;
  }
}

.support-links {
  margin-top: 5rem;
  background-color:white;
  border-radius: 20px;
  padding-right: 10px;
  .line-link{
    color:#4daf42!important;
   
  }
  .support-select{
    color: #4daf42!important;
    border-bottom: none !important;
    font-weight: bold;


  }
  ul {
    list-style: none;
  }
  li {
    color: white;
    font-size: 13px;
    margin-right: 17px;
    text-transform: uppercase;
    cursor: pointer;
    &:hover {
      text-decoration: none;
      color: #fff;
    }
  }
}
.support-layf {
  position: absolute;
  z-index: 20;
  top: 0;
}
.support-gallery {
  margin-top: 1rem;
  position: relative;
  z-index: 20;
  .box {
    background-color: white;
    width: 100%;
    height: 490px;
    border-radius: 30px;
    box-shadow: 5px 5px 20px gray;
    img {
      width: 100%;
      height: 200px;
      object-fit: cover;
      border-top-right-radius: 30px;
      border-top-left-radius: 30px;
    }
    .box-text {
      padding: 10px;
      span {
        font-weight: bold;
        font-size: 22px;
        margin: 0 0 5px 0;
        text-overflow: ellipsis;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        line-break: anywhere;
      }
      p {
        font-size: 12px;
        margin: 0;
        text-overflow: ellipsis;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        line-break: anywhere;
        span{
          font-size: 12px;
          margin: 0;
          text-overflow: ellipsis;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 4;
          -webkit-box-orient: vertical;
          line-break: anywhere;
          font-weight: 400;
        }
      }
    }
    .box-range {
      padding: 10px;
      .form-control-range {
        -webkit-appearance: none;
        width: 100%;
        height: 3px;
        background: #d3d3d3;
        outline: none;
        opacity: 0.8;
        border-radius: 20px;
        -webkit-transition: 0.2s;
        transition: opacity 0.2s;
        &:hover {
          opacity: 1;
        }
      }
      .form-control-range::-webkit-slider-thumb {
        -webkit-appearance: none;
        appearance: none;
        width: 10px;
        height: 10px;
        background: #4daf42;
        cursor: pointer;
        border-radius: 50%;
        opacity: 1;
        &:hover {
          opacity: 1;
        }
      }
      .range-percent {
        text-transform: uppercase;
        p {
          font-size: 12px;
          margin: 0 0 10px 0;
          color: #4daf42;
        }
        span {
          font-weight: bold;
          font-size: 10px;
        }
      }
      .range-about {
        p {
          font-size: 10px;
        }
      }
    }
    .box-link {
      display: flex;
      justify-content: center;
      position: absolute;
      bottom: 0;
      left: 27%;
      p {
        background-color: #4daf42;
        color: white;
        padding: 2px 55px;
        border-radius: 20px;
        font-size: 12px;
      }
      a:hover {
        text-decoration: none;
      }
    }
  }
}
.leaf {
  margin-bottom: 4rem;
  position: relative;
  z-index: 20;
  right: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  // height: 410px;
  .pic {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 20px;
  }
}

.support-leaf2 {
  position: absolute;
  z-index: -1;
  bottom: 5rem;
  left: -20rem;
  transform: rotate(170deg);
  &:before {
    content: " ";
    position: absolute;
    width: 1050px;
    height: 1050px;
    background-image: url("./../img/border.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    top: -2.5rem;
    right: -3.5rem;
    z-index: -1;
  }
  img {
    width: 60rem;
    height: 60rem;
  }
}
.support-leaf1 {
  position: absolute;
  z-index: -1;
  bottom: 20rem;
  right: -45rem;
  transform: rotate(170deg);
}
.support-leaf3 {
  position: absolute;
  z-index: -1;
  bottom: -15rem;
  right: -58rem;
}
.box-links {
  padding: 0;

  a {
    color: #4daf42;
    padding: 8px 50px;
    border: 1px solid #4daf42;
    border-radius: 20px;
    font-size: 13px;
    &:hover {
      color: #fff;
      background-color: #4daf42;
      transition: all 0.4s;
      text-decoration: none;
    }
  }
}
.staff {
  a {
    &:hover {
      text-decoration: none;
    }
  }
  .staff-inform {
    position: relative;
    width: 100%;
    //padding: 50px;
    // height: 600px;
    border-radius: 20px;
    margin-bottom: 30px;
    transform: scale(1);
    transition: all 0.5s;
    &:hover {
      transform: scale(1.1);
    }
    img {
      // position: absolute;
      width: 100%;
      height: 430px;
      border-radius: 20px;
      opacity: 0.9;
      object-fit: cover;
      box-shadow: 5px 5px 5px #ccc;
      transition: all 0.5s;
      &:hover {
        opacity: 1;
      }
    }
    .about {
      // padding: 20px 30px;
      position: absolute;
      z-index: 10;
      color: white;
      bottom: -20rem;
      //flex-direction: column;
      text-align: center;
      top: 57%;
      left: 50%;
      transform: translate(-50%, 0%);
      p {
        font-size: 25px;
        font-weight: bold;
      }
      span {
        font-size: 20px;
      }
    }
  }
}

.gallery-box {
  position: relative;
  display: block;
  background-color: white;
  width: 100%;
  height: 300px;
  border-radius: 30px;
  box-shadow: 5px 5px 20px gray;
  img {
    position: absolute;
    z-index: 10;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 20px;
    top: 0;
    left: 0;
  }
  .gallery-box-text {
    position: absolute;
    z-index: 11;
    top: 30%;
    left: 5%;
    padding: 10px;
    color: white;
    p {
      font-weight: bold;
      font-size: 20px;
      margin: 0 0 5px 0;
    }
    span {
      font-size: 10px;
      text-indent: 2em;
    }
  }
}
.full-gallery {
  overflow: hidden;
  background-color: #f6f6f6;
}
.full-gallery-content {
  margin: 5rem 15rem;
  h6 {
    font-weight: bold;
    font-size: 46px;
    margin: 0;
  }
  p {
    font-size: 16px;
    margin: 10px 0 20px 0;
  }
  img {
    width: 100% !important;
    height: 400px;
    object-fit: cover;
    cursor: pointer;
  }
  .arrows {
    .back {
      position: relative;
      z-index: 40;
      top: 14rem;
      right: -4.5rem;
      width: 54px !important;
      height: 25px !important;
      cursor: pointer;
    }
    .next {
      position: relative;
      z-index: 400;
      top: 13rem;
      right: 42rem;
      width: 54px !important;
      height: 25px !important;
      transform: rotate(-180deg);
      cursor: pointer;
    }
  }
}
.full-staff-content {
  // margin: 5rem 15rem;
  .emp-info {
    text-align: justify;
    padding: 0px 30px 0px 0px;
    height: 450px;
    overflow-x: hidden;
    overflow-y: auto;

    ::-webkit-scrollbar {
      width: 5px;
    }
    /* Track */
    ::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px grey;
      border-radius: 20px;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: #4daf42;
      border-radius: 10px;
    }
  }
  h6 {
    font-weight: bold;
    font-size: 46px;
    margin: 0;
  }
  span {
    font-size: 16px;
    color: black;
  }
  p {
    font-size: 16px;
    margin: 10px 0 20px 0;
    // width: 80%;
  }
  .profile-img {
    width: 420px;
    height: 520px;
    position: relative;
    margin-top: 2rem;
    img {
      position: absolute;
      width: 100%;
      height: 100%;
      border-radius: 20px;
      object-fit: cover;
    }
  }
}
.section-contact {
  overflow: hidden;
  background-color: #4daf42;
  height: 100vh;
}
.cancel {
  margin-top: 25px;
  img {
    width: 30px;
    height: 30px;
  }
}
.contact-text {
  color: white;
  margin: 0 10rem;
  p {
    font-size: 46px;
    font-weight: bold;
  }
  span {
    font-size: 14px;
  }
  form {
    margin-top: 1rem;
    input[type="date"]:before {
      content: attr(placeholder) !important;
      color: white;
      margin-right: 0.5em;
      &:focus {
        color: white;
      }
    }
    input[type="date"]:focus:before,
    input[type="date"]:valid:before {
      content: "";
    }
    input[type="date"]::-webkit-calendar-picker-indicator {
      cursor: pointer;
      border-radius: 4px;
      margin-right: 2px;
      opacity: 0.7;
      width: 20px;
      height: 20px;
      filter: invert(1);
    }
    input[type="date"]::-webkit-calendar-picker-indicator:hover {
      opacity: 1;
    }
    input[type="date"]::-webkit-datetime-edit-year-field:not([aria-valuenow]) {
      color: transparent;
    }

    input {
      width: 100%;
      background: transparent;
      border: none;
      outline: none;
      border-bottom: 1px solid #ccc;
      padding: 10px;
      font-size: 14px;
      color: white;
      &::-webkit-input-placeholder {
        /* Edge */
        color: white;
      }
      &:-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: white;
      }
      &::placeholder {
        color: white;
      }
      &:focus {
        color: white;
      }
    }
  }
}
.donate-form-button {
  background-color: #4daf42;
  outline: none;
  border: none;
  font-size: 12px;
  color: white;
  border-radius: 20px;
  padding: 10px 20px;
}
.donate-form {
  form {
    margin-top: 1rem;
    select {
      background: transparent;
      border: none;
      outline: none;
      border-bottom: 1px solid #4daf42;
      padding: 10px 20px;
      font-size: 14px;
      color: #4daf42;
      &::-webkit-input-placeholder {
        /* Edge */
        color: #4daf42;
      }
      &:-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: #4daf42;
      }
      &::placeholder {
        color: #4daf42;
      }
      &:focus {
        color: #4daf42;
      }
    }
    input {
      width: 100%;
      background: transparent;
      border: none;
      outline: none;
      border-bottom: 1px solid #ccc;
      padding: 10px 20px;
      font-size: 14px;
      &::-webkit-input-placeholder {
        /* Edge */
        color: black;
      }
      &:-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: black;
      }
      &::placeholder {
        color: black;
      }
      &:focus {
        color: black;
      }
    }
  }
}
.w-80 {
  width: 80%;
}
.font-14 {
  font-size: 14px;
}
.no-wr {
  white-space: nowrap;
}
.pointer {
  cursor: pointer;
}
.input-before {
  &:before {
    content: "*";
    position: absolute;
    width: 20px;
    height: 20px;
    font-size: 30px;
    top: 13px;
    right: 20px;
    color: #fff;
    z-index: 5;
  }
}

.form-select {
  width: 100%;
  background-color: transparent;
  outline: none;
  border: none;
  color: #fff;
  border-bottom: 1px solid #ccc !important;
  margin-top: 15px;
  padding: 0 0 10px 7px;
  option {
    color: black;
  }
}
.progress-bar {
  background-color: #4daf42 !important;
  height: 100%;
}
.file-upload-box {
  overflow-y: auto;
  overflow-x: hidden;

  display: flex;
  align-items: center;
  justify-content: center;
  /* width */
  &::-webkit-scrollbar,
  &::-webkit-scrollbar {
    width: 5px;
    -webkit-appearance: none;
    background-color: #4daf42;
  }
  /* Track */
  &::-webkit-scrollbar-track,
  &::-webkit-scrollbar-track {
    border-radius: 10px;
    -webkit-appearance: none;
  }
  /* Handle */
  &::-webkit-scrollbar-thumb,
  &::-webkit-scrollbar-thumb {
    background: #ccc;
    padding-left: 20px;
    -webkit-appearance: none;
  }
}
.progress-file {
  // background-color: white;
  // height: 80px;
  // border-radius: 10px;

  .progress {
    height: 15px !important;
    border-radius: 10px;
  }
}
.uploaded-file {
  border-radius: 10px;
  img {
    width: 100px;
    height: 100px;
    border-radius: 10px;
    object-fit: cover;
  }
}
.delete-file {
  border-radius: 50%;
  background-color: red;
  padding: 1px 6px;
  position:absolute;
  top: -10px;
  right: 12px;
  cursor: pointer;
  div {
    background-color: transparent;
    border: none;
    border-radius: 50%;
  }
  img {
    width: 15px;
    height: 15px;
  }
}

.contact-link {
  margin-top: 1rem;
  button {
    color: #fff;
    background-color: transparent;
    padding: 10px 50px;
    border-radius: 50px;
    font-weight: bold;
    font-size: 14px;
    border: 3px solid white;
    margin: 0 10px;
    outline: none;
    transition: all 0.3s;
    &:hover {
      text-decoration: none;
      color: #4daf42;
      background-color: white;
    }
  }
}
input[type="file"] {
  display: none;
}
.upload-file {
  color: #fff;
  background-color: transparent;
  padding: 10px 50px;
  border-radius: 50px;
  font-weight: bold;
  font-size: 14px;
  border: 3px solid white;
  margin: 0 10px;
  cursor: pointer;
  transition: all 0.3s;
  &:hover {
    text-decoration: none;
    color: #4daf42;
    background-color: white;
  }
}
.search {
  position: absolute;
  top: 14rem;
  right: 3rem;
  input {
    outline: none;
    border: 3px solid white;
    background-color: transparent;
    border-radius: 30px;
    padding: 0 10px;
    &::-webkit-input-placeholder {
      /* Edge */
      color: #fff;
    }
    &:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: #fff;
    }
    &::placeholder {
      color: #fff;
      font-size: 12px;
      padding: 5px 0;
    }
    &:focus {
      outline: none;
      color: white;
    }
  }
}
.donates {
  margin-top: 3rem;
  .donates-board {
    background-color: #fff;
    box-shadow: 2px 10px 20px gray;
    border-radius: 10px;
    width: 100%;
    padding: 20px 15px;
    p {
      font-size: 14px;
      margin: 0;
    }
    span {
      font-size: 15px;
    }
    a {
      &:hover {
        text-decoration: none;
      }
      span {
        font-size: 12px;
        color: black;
      }
      p {
        font-size: 14px;
        margin: 0;
        color: black;
      }
    }

    .price {
      color: #4daf42;
      font-weight: bold;
    }
    .name{
      width: 13%;
    }
    .supportname{
      width: 10%;
    }
    .amount{
      width: 40%;
    }
  }
}
.desc {
  p,
  span,
  div {
    background: transparent !important;
    font-size: 14px !important;
    font-family: "Roboto Consended", sans-serif !important;
  }
}

@media (max-height: 770px) {
  .main-card div a div {
    height: 200px;
    width: 200px;
  }
}
@media (min-width: 1537px) {
  .header-link {
    a {
      font-size: 16px;
    }
  }
  .section-link {
    margin-top: 2rem;
    a {
      font-size: 20px;
    }
  }
  .section-text {
    p {
      font-size: 18px;
    }
    span {
      font-size: 16px;
    }
  }
  .range-percent {
    margin-top: 20px;
    p {
      font-size: 18px;
    }
    span {
      font-size: 14px;
    }
  }
  .range-about {
    p {
      font-size: 14px;
    }
  }
  .links {
    font-size: 14px;
    margin-top: 2rem;
  }
  .leaf1 {
    top: -6rem;
    right: -15rem;
    &:before {
      width: 1350px;
      height: 1350px;
      right: -2rem;
    }
    img {
      width: 80rem;
      height: 80rem;
    }
  }
  .section2 {
    margin-top: 7rem;
  }
  .section-two-links {
    font-size: 14px;
    margin-top: 3rem;
  }

  .section-three-text {
    p {
      font-size: 48px;
    }
    span {
      font-size: 16px;
    }
  }
  .section-three-numbers {
    p {
      font-size: 16px;
    }
    span {
      font-size: 62px;
    }
  }
  .section-four-links {
    a {
      font-size: 14px;
    }
  }
  .projects {
    margin-top: 3rem;
    .project-text {
      a {
        font-size: 14px;
      }
      h3 {
        font-size: 22px;
      }
      p {
        font-size: 14px;
      }
      span {
        font-size: 12px;
      }
    }
    .project {
      height: 280px;
    }
  }
  .events {
    margin-top: 10px;
    overflow: hidden;
    .slick-slider {
      .slick-list {
        padding: 0 !important;
        overflow: unset;
      }
      .slick-slide {
        transition: 0.3s all;
        padding: 0 10px;
      }

      .slick-center {
        transition: 0.3s all;
      }
    }
    .event {
      height: 270px;
      .event-text {
        p {
          font-size: 22px;
        }
        span {
          font-size: 12px;
        }
      }
      .event-range {
        bottom: 45px;
        width: 90%;
        .range-percent {
          p {
            font-size: 14px;
          }
          span {
            font-size: 12px;
          }
        }
        .range-about {
          p {
            font-size: 12px;
          }
        }
      }
      .event-link {
        bottom: 10px;
        left: 59%;
        p {
          font-size: 14px;
        }
        span {
          font-size: 14px;
        }
      }
    }
  }
  .leaf-celebrity {
    margin-top: 4rem;
    .leaf2 {
      width: 70rem;
      height: 70rem;
    }
    .leaf-2 {
      top: -15rem;
      left: -13rem;
      width: 1300px !important;
      height: 1300px;
      mask-size: 1100px;
      .name {
        top: 40rem;
        left: 40rem;
        p {
          font-size: 48px;
        }
        span {
          font-size: 16px;
        }
      }
    }
    &:before {
      width: 1200px;
      height: 1200px;
      top: -7rem;
    }
  }
  .section-fifth-slider-arrow {
    bottom: 17rem;
    left: 5rem;
    p {
      font-size: 16px;
    }
  }
  .footer {
    .footer-text {
      p {
        font-size: 14px;
        margin-top: 40px;
      }
      span {
        font-size: 14px;
      }
    }
  }
  .footer-social-icon {
    // margin-top: 8rem;
    p {
      font-size: 14px;
    }
  }
  .footer-links {
    p {
      font-size: 18px;
    }
    li {
      margin-bottom: 10px;
    }
    a {
      font-size: 14px;
    }
  }

  .donate-number {
    padding-top: 30px;
    div {
      margin-right: 9rem;
    }
    p {
      font-size: 32px;
    }
    span {
      font-size: 16px;
    }
  }
  .donor {
    margin-top: 3rem;
    div {
      width: 70px;
      height: 70px;
    }
  }
  .donor-link {
    margin-top: 50px;
    a {
      font-size: 16px;
    }
  }
  .donate-leaf {
    right: -15rem;
    &:before {
      width: 1200px;
      height: 1200px;
    }
    img {
      width: 70rem;
      height: 70rem;
    }
  }
  .donate-slider2 {
    margin-top: 14rem;
    width: 87%;
    .donate-slider1-box {
      height: 400px;
    }
    .donate-slider2-box {
      height: 170px;
    }
  }
  .donate-slider-links {
    margin-top: 10rem;
    p {
      font-size: 14px;
    }
  }
  .donate-text {
    margin-top: 80px;
    p {
      font-size: 18px;
      padding-top: 40px;
    }
  }
  .girl {
    bottom: 0;
    width: 55rem !important;
    height: 56rem !important;
  }
  .donate-link {
    margin-bottom: 2rem;
    margin-top: 5rem;
    a {
      font-size: 20px;
    }
  }
  .donate-contact {
    left: 16rem;
    top: 16rem;
    width: 250px;
    p {
      font-size: 27px;
    }
    span {
      font-size: 16px;
    }
    p a {
      font-size: 27px;
    }
  }
  .donate-section-four-text {
    p {
      font-size: 18px;
    }
    span {
      font-size: 16px;
    }
  }
  .payment {
    padding-top: 30px;
    .payment-amount {
      p {
        font-size: 20px;
      }
    }
    .payment-number {
      width: 20%;
      p {
        font-size: 24px;
      }
      span {
        font-size: 14px;
      }
    }
  }
  .payment-text {
    p {
      font-size: 14px;
    }
  }
  .payment-about {
    padding-top: 30px;
    p {
      font-size: 14px;
    }
    .payment-total {
      width: 20%;
      p {
        font-size: 18px;
      }
      span {
        font-size: 12px;
      }
    }
  }
  .payment-social-icons {
    left: 1rem;
    bottom: 6rem;
  }
  .payment-leaf1 {
    top: -14rem;
    right: -11rem;
    &:before {
      width: 1350px;
      height: 1350px;
      top: -2rem;
      right: -2.5rem;
    }
    img {
      width: 80rem;
      height: 80rem;
    }
  }
  .payment-card {
    top: 5rem;
    right: 3rem;
    img {
      width: 50rem;
    }
  }
  .payment-price {
    margin-top: 3rem;
    p {
      font-size: 16px;
    }
    span {
      font-size: 26px;
    }
    span p {
      font-size: 14px;
    }
  }
  .accept-payment {
    margin-top: 3rem;
    p {
      font-size: 16px;
    }
    a {
      font-size: 14px;
    }
  }
  .back-track {
    margin-top: 4rem;
    p {
      font-size: 14px;
    }
  }

  .payment-video {
    margin-top: 6rem;
    p {
      font-size: 16px;
    }
    .video {
      width: 250px;
      height: 150px;
      span {
        font-size: 16px;
      }
    }
  }
  .section-terms {
    p {
      font-size: 16px;
    }
  }
  .support-links {
    margin-top: 10rem;
    a {
      font-size: 14px;
    }
  }
  .support-gallery {
    margin-top: 2rem;
    position: relative;
    z-index: 20;
    .box {
      width: 100%;
      height: 500px;
      // height: max-content;
      .box-text {
        p {
          font-size: 14px;
        }
        span {
          font-size: 19px;
        }
      }
      .box-range {
        bottom: 40px;
        .range-percent {
          p {
            font-size: 14px;
          }
          span {
            font-size: 12px;
          }
        }
        .range-about {
          p {
            font-size: 12px;
          }
        }
      }
      .box-link {
        left: 32%;
        p {
          font-size: 14px;
        }
      }
    }
    .box-links {
      //left: 32%;
      bottom: 3%;
    }
  }
  .leaf {
    height: 530px;
  }
  .leafRight {
    position: absolute;
    z-index: 15;
    top: -5rem;
    right: 8rem;
    width: 100%;
    height: 100%;
    img {
      width: 76rem;
      height: 76rem;
    }
  }
  .main-card a {
    height: 250px;
    width: 250px;
    img {
      width: 100px;
      height: 100px;
    }
  }
  .social-icons {
    bottom: 24rem;
  }
  .gallery-box {
    background-color: white;
    width: 100%;
    height: 400px;
    .gallery-box-text {
      top: 35%;
      padding: 0 10px;
      p {
        font-size: 24px;
      }
      span {
        font-size: 14px;
      }
    }
  }
  .contact-text {
    margin: 1rem 20rem;
    p {
      font-size: 50px;
    }
    span {
      font-size: 16px;
    }
    form {
      margin-top: 2rem;
    }
  }
  .contact-link {
    margin-top: 2rem;
    a {
      font-size: 16px;
    }
  }
}

@media (max-width: 1200px) {
  .payment-card {
    right: -2rem;
  }
  .payment-leaf1 {
    right: -24rem;
  }
  .donate-leaf {
    top: -7rem;
    right: -26rem;
  }
  .donate-slider2 {
    width: 100%;
    margin-left: 1rem;
  }
  .events .event .event-range {
    padding: 10px 180px 0 10px;
  }
  .main-card a {
    height: 250px;
    width: 250px;
    img {
      width: 100px;
      height: 100px;
    }
  }
  .donate {
    margin-top: 1.5rem;
  }
  .leafRight {
    top: -8rem;
    right: 10rem;
    img {
      width: 80rem;
      height: 80rem;
    }
  }
  .header {
    padding: 10px 30px !important;
  }
  .navbar-brand {
    img {
      width: 150px;
    }
  }
  .header-link {
    li {
      margin-right: 25px;
      &:before {
        display: none;
      }
    }
  }
  .section-three-text {
    p {
      font-size: 25px;
      width: 100%;
    }
    span {
      font-size: 13px;
    }
  }
  .section-three-numbers {
    font-weight: bold;
    color: white;
    p {
      font-size: 12px;
      margin: 0;
    }
    span {
      font-size: 40px;
    }
  }
  .box-link {
    left: 24%;
  }
  .leaf-celebrity {
    .leaf2 {
      left: -22rem;
    }
    &:before {
      left: -23.5rem;
    }
  }
  .slider-arrow {
    display: none;
  }
  .section-fifth-slider-arrow {
    display: none !important;
  }
  .footer {
    &:before {
      transform: rotate(85deg);
      right: -26rem;
    }
  }

  .footer-leaf {
    img {
      right: -2rem;
      bottom: -25px;
    }
    a {
      top: -45px;
      right: 0;
      font-size: 11px;
    }
  }
  .navbar-expand-lg .navbar-toggler {
    display: block !important;
  }
  .header-link {
    li {
      margin-right: 30px;
    }
    a {
      font-size: 14px;
    }
  }
  .girl {
    left: -7rem;
    bottom: 0;
    width: 50rem;
    height: 50rem;
  }
  .contact-text {
    margin: 5rem 1rem;
  }
  .section-link {
    a {
      font-size: 16px;
    }
  }
  .section-text {
    p {
      font-size: 16px;
    }
    span {
      font-size: 14px;
    }
  }
  .home a span {
    padding: 10px 23px;
    font-size: 15px;
  }
  .partnersImg {
    width: 100%;
    height: 150px;
  }
  .footer-leaf {
    span {
      bottom: 1em;
      right: -1rem;
    }
    &:before {
      bottom: -4rem;
      right: -4rem;
      transform: rotate(65deg);
    }
  }
  .main {
    // min-height: 100%;
    padding: 0 50px !important;
  }
  .donate-section-four-text p {
    font-size: 14px;
  }
  .section-terms {
    p {
      font-size: 15px;
    }
  }
  .donor-link {
    margin: 15px 0 0 40px;
  }
  .report{
    flex-direction: column;
    
    
    .report-card{
      width: 90% !important;
    }
    // .leaf{
    //   height: 200px !important;
    // }
    .about{
      display:flex;
      flex-direction:column-reverse !important;
    }
  }

  .donates .donates-board .supportname{
    width: 20%;
  }
  .donates .donates-board .amount{
    width: 18%;
  }
  .support-button{
    width:100%;
    display:flex;
    justify-content:flex-end;
 
  }

  .media-border{
    
    padding: 5px 5px 5px 10px;
  
  
  }
  
}
@media (max-width: 769px) {
  .year-slider {
    .years-table {
      .number {
        margin-right: 20px;
      }
    }
  }

  .staff-inform {
    & img {
      height: 400px !important;
    }
  }

  .margin-t {
    margin-top: 120px;
  }
  .footer {
    &:before {
      top: 10rem;
      right: -26rem;
    }
    p {
      font-size: 15px !important;
    }
    a {
      font-size: 11px;
    }
    .footer-social-icon li {
      list-style: none;
      margin-right: 15px;
    }
  }
  .contact-text {
    margin: 5rem 0;
  }
  .girl {
    display: none;
  }
  .payment-leaf1 {
    right: -21rem;
  }
  .support-links {
    margin-top: 2rem;
  }
  .events .event .event-range {
    padding: 10px 260px 0 10px;
  }
  .donation {
    display: none;
  }

  .support-gallery {
    .box {
      height: 470px;
    }
  }

  .box-link {
    left: 50% !important;
    transform: translate(-50%, 0%);
  }

  .staff-inform {
    padding: 0 !important;

    .about {
      width: 68%;
      top: 60% !important;

      & p {
        font-size: 26px !important;
      }
    }
  }

  .donate-contact {
    top: 17rem !important;
  }
  .year-slider {
    & .years-table {
      margin: 0px 0px 5rem !important;
    }
  }
}
@media (max-width: 426px) {
  .section-text h2 {
    font-size: 42px !important;
  }

  .full-staff-content .profile-img {
    width: auto;
    margin-bottom: 2rem;
  }

  .leafRight {
    top: -8rem;
    right: 19rem;
  }
  .donate-contact {
    left: 5rem;
  }
  .support-links {
    margin-top: 2rem;
    li {
      color: black;
      font-size: 11px;
      margin-right: 10px;
      &:hover {
        color: black;
      }
    }
  }
  .line-link {
    position: relative;
    z-index: 21;
    &::before {
      content: "";
      position: absolute;
      width: 0;
      z-index: -1;
      transition: all 0.5s ease-in;
    }
    &:hover {
      &::before {
        left: 0;
        bottom: -5px;
        width: 100%;
        margin-top: -5px;
        background: black;
        height: 2px;
      }
    }
  }
  .payment-card,
  .payment-leaf1 {
    display: none;
  }
  .section-contact {
    height: 100%;
  }
  .footer:before {
    top: 59rem;
    right: -28rem;
  }
  .footer-leaf {
    span {
      bottom: 3rem;
      right: -2rem;
      font-size: 11px;
    }
    &:before {
      content: " ";
      position: absolute;
      width: 150px;
      height: 150px;
      background-image: url(/static/media/leaf2.f151eb41.svg);
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      bottom: -1rem;
      right: -4rem;
      transform: rotate(65deg);
      z-index: -1;
    }
  }

  .main-card {
    margin: 10px;
  }
  .social-icons {
    position: absolute;
    z-index: 1;
    left: 4px;
    bottom: 270px;
  }
  .footer {
    overflow: hidden;
    background-color: #4daf42;
    width: 100%;
    height: 100%;
    padding: 30px 50px !important;
    display: block;
  }
  .main,
  .main-100 {
    padding: 0 20px !important;
  }
  .partnersImg {
    margin-left: 80px;
  }
  .our-mission {
    padding: 0 20px;
  }
  .section-text {
    p {
      font-size: 14px;
    }
    a {
      font-size: 14px;
    }
  }
  .section-three {
    padding: 0 20px;
  }
  .events .event .event-range {
    padding: 10px 143px 0 10px;
  }
}
@media (max-width: 376px) {
  .donor {
    margin-top: 1rem;
    div:nth-child(2) {
      left: 35px;
    }
    div:nth-child(3) {
      left: 55px;
    }
    div:nth-child(4) {
      left: 75px;
    }
    div:nth-child(5) {
      left: 95px;
    }
  }
  .donor-link {
    margin: 15px 0 0 0;
    a {
      font-size: 14px;
      color: white;
      background-color: #4daf42;
      border-radius: 20px;
      padding: 10px 20px;
      &:hover {
        text-decoration: none;
        color: white;
      }
    }
  }
}

@media (min-width: 1280px) and (max-width: 1400px) and (max-height: 768px) {
  .header {
    .header-link a {
      color: #357d2d;
      font-size: 13px;
      text-decoration: none;
    }
  }
  
}

.ant-switch.ant-switch-checked {
  background: #4daf42 !important;
  &:checked{  
    background: #4daf42 !important;
  }
  &:hover{
    background: #4daf42 !important;
  }
}

.flex{
  display:flex;
  gap: 10px;
}
.personsSelect  {
  border-bottom: 1px solid #ccc !important;
  padding: 10px 20px;
  font-size: 14px;
  color: black !important;
  &::-webkit-input-placeholder {
    /* Edge */
    color: black !important;
  }
  &:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: black !important;
  }
  &::placeholder {
    color: black !important;
  }
  &:focus {
    color: black !important;
  }
}
.anonim{
  width: 50%;
  padding-top: 10px;
  color:red;
}


.apply-title{
  color:white;
}
.file-upload-box {
  padding-right: 20px;
}

.report{
  display: flex;
  justify-content: space-between;
  .donate-card{
  
    display: flex;
    flex-direction: column;
    gap: 10px;
  
  }
  
  .report-card{
    width: 49%;
  }
}

.gap-30{
  gap: 30px;
}
